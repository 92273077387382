import css from './EmailUpdateModal.module.css'
import mobx from '../../../mobx/mobx'
import {observer} from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import {  motion} from 'framer-motion'
import gsap from 'gsap'
import HttpUtils from '../../../http/utils'
import { isEmail } from 'validator';


const EmailUpdateModal =  observer(() => {
    const [email,setEmail] = useState('')
    const modalRef = useRef(null)


    const close = () =>{
        gsap.to(modalRef.current,{opacity:0})
        setTimeout(()=>{mobx.setEmailUpdateModal(false)},[300])
    }

    const updateEmail = () =>{
        if(email && isEmail(email)){
            const formData = new FormData();
            formData.append('email2', email);
            formData.append('id', mobx.User.id);
            gsap.to(modalRef.current,{opacity:0})
            setTimeout(()=>{HttpUtils.updateEmail(formData)},[300])
        }else{
            alert("Не корректный email!")
        }
    }
    useEffect(()=>{
        setEmail('')
        return ()=>{
            setEmail('')
        }
    },[mobx.EmailUpdateModal])
    return ( 
<>
    <motion.section ref={modalRef} initial={{opacity: 0 }} whileInView={{opacity:1}} className={css.container} onClick={()=>mobx.setEmailUpdateModal(false)}>
        <div className={css.modalWind} onClick={e => e.stopPropagation()}>
        <div className={css.close} onClick={close}>x</div>
        <input className={css.inputName} placeholder='Введите новый email' value={email} onChange={(e)=> setEmail(e.target.value)}  />
        <button className={css.button} onClick={()=>updateEmail()}>Изменить</button>
        </div>
    </motion.section>
</>
     );
})
 
export default EmailUpdateModal;