import css from './AdminLayout.module.css'
import { Outlet } from 'react-router-dom';
import mobx from '../../../mobx/mobx'
import { observer } from 'mobx-react-lite'
import CategoryCreateModal from '../CategoryCreateModal/CategoryCreateModal';
import CategoryUpdateModal from '../CategoryUpdateModal/CategoryUpdateModal';
import Header from '../Header/Header';
import UserModal from '../UserModal/UserModal';

const AdminLayout = observer(() => {
  return (
    <div className={css.container}>
      {mobx.categoryCreateModal&&<CategoryCreateModal/>}
      {mobx.categoryUpdateModal&&<CategoryUpdateModal/>}
      {mobx.UserModal&&<UserModal/>}
      <Header/>
        <Outlet />
    </div>
  )
})

export default AdminLayout
