import css from './CategoryCreateModal.module.css'
import mobx from '../../../mobx/mobx'
import { useState } from 'react'
import {motion} from 'framer-motion'
import HttpUtils from '../../../http/utils'

const CategoryCreateModal = () => {
    const [name , setName] = useState()
    const [index , setIndex] = useState()
    const add = () => {
        if(name && index){
            const formData = new FormData();
            formData.append('name', name);
            formData.append('index', index);
            HttpUtils.createCategory(formData)
        }else{
            alert('Заполните все поля!')
        }
    }

  return (
<motion.section initial={{opacity:0}} animate={{opacity:1}} className={css.container} onClick={()=>mobx.setCategoryCreateModal(false)}>
    <div className={css.modalWind} onClick={e => e.stopPropagation()}>
        <h2 className={css.header}>Добавление категории</h2>
        <input className={css.inputName} value={name} onChange={(e)=>setName(e.target.value)} placeholder='Название категории' type='text'/>
        <input className={css.inputName} value={index} onChange={(e)=>setIndex(e.target.value)} placeholder='Индекс категории' type='number'/>
        <button className={css.button} onClick={add} >Добавить</button>
    </div>
</motion.section>
  )
}

export default CategoryCreateModal