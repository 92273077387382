import {makeAutoObservable} from 'mobx'
class Mobx {
    constructor(){
        makeAutoObservable(this)
    }

    productArray = []
    categoryArray=[]
    oneProduct ={}
    applicationModal = false
    categoryId=0
    categoryCreateModal = false
    categoryUpdateModal = false
    ForgotPasswordModal = false
    userAutorization = false
    UserModal = false
    PasswordUpdateModal = false
    EmailUpdateModal = false
    User = {}

    setUser(user){
        this.User = user
    }
    setForgotPasswordModal(bool){
        this.ForgotPasswordModal = bool
    }
    setEmailUpdateModal(bool){
        this.EmailUpdateModal = bool
    }
    setPasswordUpdateModal(bool){
        this.PasswordUpdateModal = bool
    }
    setUserModal(bool){
        this.UserModal = bool
    }
    serUserAutorization(bool){
        this.userAutorization = bool
    }
    setCategoryCreateModal(bool){
        this.categoryCreateModal = bool
    }

    setCategoryUpdateModal(bool){
        this.categoryUpdateModal = bool
    }
    setProductArray(array){
        this.productArray = array
    }
    setCategoryArray(array){
        this.categoryArray = array
    }
    setApplicationModal(bool){
        this.applicationModal = bool
    }
    setCategoryId(id){
        this.categoryId = id
    }
    setOneProduct(product){
        this.oneProduct = product
    }
}

export default new Mobx();