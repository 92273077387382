import { useEffect } from 'react'
import css from './BodyCatalog.module.css'
import mobx from '../../../mobx/mobx'
import Data from '../../../mobx/data'
import { observer } from 'mobx-react-lite'
import {AnimatePresence, motion} from 'framer-motion'
import {Link} from 'react-router-dom'
import HttpUtils from '../../../http/utils'


const BodyCatalog = observer(() => {

    return (
        <section className={css.container}>
            <AnimatePresence mode={ "popLayout"}>
            {mobx.productArray &&
                mobx.productArray.map(card => (
                    <motion.div layout  initial={{ opacity:0 }} animate={{ opacity: 1 }} exit={{ opacity:0, overflow: 'hidden' }} key={card.id} className={css.card}>
                        <img src={`${process.env.REACT_APP_API_HOST}${card.img}`} className={css.cardImg} />
                        <h2 className={css.cardHeader}>{card.name}</h2>
                        <span className={css.cardArticle}>{`Артикул: ${card.article}`}</span>
                        <span className={css.price}>{`Цена: ${card.pricePack} руб.`}</span>
                        <div className={css.buttonContainer}>
                            <Link to={`/admin/updateproduct/${card.id}`} className={css.button}>Редактировать</Link>
                            <Link className={css.button2} onClick={()=>Data.deleteProduct(card.id)}>Удалить</Link>
                        </div>
                    </motion.div>
                ))         
            }
            </AnimatePresence>
        </section>
    )
})

export default BodyCatalog