import css from './About.module.css'
import Сonsultation from '../../../components/HomeComponents/Сonsultation/Сonsultation'
import img1 from './img/img1.png'
import img2 from './img/img2.png'
import img3 from './img/img3.png'

const About = () => {
    return (
        <section className={css.container}>
            <h1 className={css.header}>Произведем и доставим</h1>
            <div className={css.card}>
                <div className={css.textContainer}>
                    <div className={css.block}></div>
                    <div className={css.text}>
                        <h2 className={css.cardHeader}>Доставка и упаковка</h2>
                        <span className={css.cardDescr}>У нас собственный автопарк, поэтому мы можем доставить доску в любом объеме. Е
                            сли вы находитесь в городе Йошкар-Ола, Казань, Москва, Чебоксары, Нижний Новгород, Ростов-на-Дону, Владимир,
                            Тольятти, Сызрань, Ульяновск или Тамбов, мы с радостью доставим груз нашим траспортом по лучшим ценам</span>
                        <span className={css.cardDescr}>Если вы не нашли свой город в списке, мы можем организовать доставкук вам транспортной компанией.</span>
                    </div>
                </div>
                <div className={css.imgContainer}>
                    <img src={img1} className={css.img} />
                </div>
            </div>
            <div className={`${css.card} ${css.left}`}>
            <div className={`${css.imgContainer} ${css.leftImg}`}>
                    <img src={img2} className={css.img} />
                </div>
                <div className={css.textContainer}>
                    <div className={`${css.block} ${css.leftBlock}`}></div>
                    <div className={css.text}>
                        <h2 className={css.cardHeader}>Собственная технология</h2>
                        <span className={css.cardDescr}>У нас есть лаборатория и мы самостоятельно разработали и внедрили технологию, 
                        и постоянно совершенствуем ее для вас, чтобы наша доска радовала вас как можно дольше.</span>
                    </div>
                </div>

            </div>
            <div className={`${css.card} ${css.lastCard}`}>
                <div className={css.textContainer}>
                    <div className={css.block}></div>
                    <div className={css.text}>
                        <h2 className={css.cardHeader}>Доставка и упаковка</h2>
                        <span className={css.cardDescr}>У нас собственный автопарк, поэтому мы можем доставить доску в любом объеме. Е
                            сли вы находитесь в городе Йошкар-Ола, Казань, Москва, Чебоксары, Нижний Новгород, Ростов-на-Дону, Владимир,
                            Тольятти, Сызрань, Ульяновск или Тамбов, мы с радостью доставим груз нашим траспортом по лучшим ценам</span>
                        <span className={css.cardDescr}>Если вы не нашли свой город в списке, мы можем организовать доставкук вам транспортной компанией.</span>
                    </div>
                </div>
                <div className={css.imgContainer}>
                    <img src={img3} className={css.img} />
                </div>
            </div>
            <Сonsultation />
        </section>

    )
}

export default About