import css from './PasswordUpdateModal.module.css'
import mobx from '../../../mobx/mobx'
import {observer} from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import {  motion} from 'framer-motion'
import gsap from 'gsap'
import HttpUtils from '../../../http/utils'
import eye from './img/eye.png'
import eyen from './img/eyen.png'


const PasswordUpdateModal =  observer(() => {
    const [password,setPassword] = useState('')
    const [pasVisible, setPasVisible] = useState(false)
    const [pas1Visible, setPas1Visible] = useState(false)
    const [password1,setPassword1] = useState('')
    const modalRef = useRef(null)


    const close = () =>{
        gsap.to(modalRef.current,{opacity:0})
        setTimeout(()=>{mobx.setPasswordUpdateModal(false)},[300])
    }

    const updatePassword = () =>{
        if(password){
            const formData = new FormData();
            if(password===password1){
                formData.append('password', password);
                formData.append('id', mobx.User.id);
                gsap.to(modalRef.current,{opacity:0})
                setTimeout(()=>{HttpUtils.updatePassword(formData)},[300])
            }else{
                alert("Введенные пароли не совпадают!")
            }

        }
    }

    useEffect(()=>{
        setPassword('')
        setPassword1('')
        setPasVisible(false)
        setPas1Visible(false)

        return ()=>{
            setPassword('')
            setPassword1('')
            setPasVisible(false)
            setPas1Visible(false)
        }
    },[mobx.PasswordUpdateModal])
    return ( 
<>
{mobx.PasswordUpdateModal&&
    <motion.section ref={modalRef} initial={{opacity: 0 }} whileInView={{opacity:1}} className={css.container} onClick={()=>mobx.setPasswordUpdateModal(false)}>
        <div className={css.modalWind} onClick={e => e.stopPropagation()}>
        <div className={css.close} onClick={close}>x</div>
        <div className={css.imputContainer}><input className={css.inputName} placeholder='Введите новый пароль' type={pasVisible? 'text':'password'} value={password} onChange={(e)=> setPassword(e.target.value)} /><img className={css.eye} src={pasVisible?eye:eyen} onClick={()=>setPasVisible(!pasVisible)}/></div>
        <div className={css.imputContainer}><input className={css.inputName} placeholder='Подтвердите пароль' type={pas1Visible? 'text':'password'} value={password1} onChange={(e)=> setPassword1(e.target.value)} /><img className={css.eye} src={pas1Visible?eye:eyen} onClick={()=>setPas1Visible(!pas1Visible)}/></div>
        <button className={css.button} onClick={()=>updatePassword()}>Изменить</button>
        </div>
    </motion.section>
}
</>
     );
})
 
export default PasswordUpdateModal;