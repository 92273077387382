import css from './Header.module.css'
import {Link} from 'react-router-dom'
import mobx from '../../../mobx/mobx'
import { Link as ScrollLink } from 'react-scroll';
import { useState } from 'react';
import {motion} from 'framer-motion'

const Header = () => {
  const [modal ,setModal] = useState(false)
  return (
<div className={css.container}>
  <Link to='/' className={css.logoLink}>
    <div className={css.logoContainer}>
        <span>G</span>
        <span className={css.act}>U</span>
        <span>B</span>
        <span>K</span>
        <span>I</span>
      </div>
  </Link>

    <div className={modal?`${css.navContainer} ${css.active}`:css.navContainer}>
        <nav className={modal?`${css.nav} ${css.navActive}`:css.nav}>
          <Link to='/catalog' className={css.link}  onClick={()=>setModal(false)} >КАТАЛОГ</Link>
          <Link className={css.link}  onClick={()=>setModal(false)} to='/about'>О НАС</Link>
          <ScrollLink spy={true} smooth={true} offset={0} duration={1500} to='contacts' className={css.link}  onClick={()=>setModal(false)} ><Link className={css.link} to='/'>КОНТАКТЫ</Link></ScrollLink>
          <a className={css.number}  onClick={()=>setModal(false)} >+7 906 139 00 01</a>
          <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.close} onClick={()=>setModal(false)} >x</motion.div>
        </nav>
        <button className={css.button} onClick={()=>mobx.setApplicationModal(true)}>Заказать звонок</button>
        
    </div>
    <div className={css.butgerTrigger} onClick={()=>setModal(true)}>|||</div>
</div>
  )
}

export default Header
