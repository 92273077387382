import css from './Login.module.css'
import {useState} from 'react'
import HttpUtils from '../../../http/utils'
import { SmartCaptcha } from '@yandex/smart-captcha';
import ForgotPassword from '../../../components/AdminComponent/ForgotPassword/ForgotPassword';
import eye from './img/eye.png'
import eyen from './img/eyen.png'
import arrow from './img/arrow.png'
import {Link, useNavigate} from 'react-router-dom'
import {Helmet} from "react-helmet";
import mobx from '../../../mobx/mobx'

const REACT_APP_YANDEX_CAPTCHA_CODE = 'ysc1_Ve9dRXx0KWIkUZYVN6JT4r53toZqRsgLYjlmpwbf0660f10e'

const Login = () => {
    let navigate = useNavigate();
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [pasVisible, setPasVisible] = useState(false)
    const [token, setToken] = useState('');



    const clearInput = ()=>{
        setEmail('')
    }


    const logIn = async () =>{
        if(token){
            if(!email || !password){
                alert("Заполните обязательные поля!")
            }else{
                const data = await HttpUtils.login(email,password)
                if ( data){
                    navigate(`/admin`);
                }
            }
        }else{
            alert("Подтвердите, что вы не робот!")
        }
            
        
    }
    return ( 
<>
<Helmet>
    <title>Авторизация пользователя...</title>
    <meta name="description" content="Авторизация пользователя..." />
</Helmet>
<section className={css.container}>
<Link to='/'><img src={arrow} className={css.arrow}/></Link>
<ForgotPassword/>
    <div className={css.formConainer}>
        <div className={css.imputContainer}><input className={css.email} placeholder='Введите email' value={email} onChange={(e)=>setEmail(e.target.value)} /><span className={css.x} onClick={()=>clearInput()}>✕</span></div>
        <div className={css.imputContainer}><input className={css.password} placeholder='Введите пароль' type={pasVisible? 'text':'password'} value={password} onChange={(e)=> setPassword(e.target.value)} /><img className={css.eye} src={pasVisible?eye:eyen} onClick={()=>setPasVisible(!pasVisible)}/></div>
        <div className={css.captchaBox}>
        <SmartCaptcha  sitekey={REACT_APP_YANDEX_CAPTCHA_CODE} onSuccess={setToken} />
        </div>
        
        <button className={css.button} onClick={()=>logIn()} >Войти</button>
        <span className={css.forgotPassword} onClick={()=>mobx.setForgotPasswordModal(true)} >Забыли пароль?</span>
    </div>
</section>
</>
     );
}
 
export default Login;