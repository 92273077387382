import css from './Hero.module.css'
import bacground from './img/bacground.jpeg'
import img1 from './img/img1.jpeg'
import mobx from '../../../mobx/mobx'
import Data from '../../../mobx/data'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'

const Hero = observer(() => {
  useEffect(() => {
    Data.getCategoryData()
  }, [])
  return (
    <div className={css.container} id='hero'>
      <img src={bacground} className={css.bacgroundImg} />
      <div className={css.headerContainer}>
        <h1 className={css.header}>Хозяйственные товары от производителя </h1>
        <h2 className={css.header1}>Оптом и в розницу</h2>
      </div>
      <div className={css.buttonContainer}>
        <button className={css.button1} onClick={() => mobx.setApplicationModal(true)}>Отправить заявку</button>
        <Link to='/catalog' className={css.button2}>Смотреть каталог</Link>
      </div>
      <div className={css.cardContainer}>
        {mobx.categoryArray ?
          mobx.categoryArray.splice(0, 3).map(category => (
            <motion.div key={category.id} initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:.5,duration:.7}} viewport={{once:true}} className={css.card}>
              <img src={img1} className={css.cardImg} />
              <div className={css.cardHeaderContainer}>
                <h2 className={css.cardHeader}>{category.name}</h2>
                <Link to={`/catalog/?id=${category.id}`} className={css.link}>Смотреть</Link>
              </div>
            </motion.div>
          )) :
          <>
            <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:.5,duration:.7}} viewport={{once:true}} className={css.card}>
              <img src={img1} className={css.cardImg} />
              <div className={css.cardHeaderContainer}>
                <h2 className={css.cardHeader}>Губки для посуды</h2>
                <Link to='/catalog' className={css.link}>Смотреть</Link>
              </div>
            </motion.div>
            <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:.5,duration:.7}} viewport={{once:true}} className={css.card}>
              <img src={img1} className={css.cardImg} />
              <div className={css.cardHeaderContainer}>
                <h2 className={css.cardHeader}>Губки для тела</h2>
                <Link to='/catalog' className={css.link}>Смотреть</Link>
              </div>
            </motion.div>
            <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:.5,duration:.7}} viewport={{once:true}} className={css.card}>
              <img src={img1} className={css.cardImg} />
              <div className={css.cardHeaderContainer}>
                <h2 className={css.cardHeader}>Губки для посуды</h2>
                <Link to='/catalog' className={css.link}>Смотреть</Link>
              </div>
            </motion.div>
          </>
        }

      </div>
    </div>
  )
})

export default Hero
