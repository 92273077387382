import Contacts from '../../components/HomeComponents/Contacts/Contacts'
import Сonsultation from '../../components/HomeComponents/Сonsultation/Сonsultation'
import Hero from '../../components/HomeComponents/Hero/Hero'
import Products from '../../components/HomeComponents/Products/Products'
import css from './Home.module.css'
import Service from '../../components/HomeComponents/Service/Service'

const Home = () => {
  return (
<div className={css.container}>
    <Hero/>
    <Products/>
    <Service/>
    <Сonsultation/>
    <Contacts/>
</div>
  )
}

export default Home
