import React from 'react';

import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import HomeLayout from './components/HomeComponents/HomeLayout/HomeLayout';
import Catalog from './pages/Home/Catalog/Catalog';
import Product from './pages/Home/Product/Product';
import AdminLayout from './components/AdminComponent/AdminLayout/AdminLayout';
import AdminHome from './pages/Admin/AdminHome';
import AddProduct from './pages/AddProduct/AddProduct';
import Login from './pages/Admin/Login/Login';
import UpdateProduct from './pages/UpdateProduct/UpdateProduct';
import Confidential from './pages/Home/Confidential/Confidential';
import About from './pages/Home/About/About';

function App() {
  return (
    <div className="App">
    <Routes>
      <Route path='/'element={<HomeLayout/>}>
          <Route path="/" element={<Home />}/>
          <Route path="/catalog" element={<Catalog />}/>
          <Route path="/catalog/:id" element={<Catalog />}/>
          <Route path="/product/:id" element={<Product/>}/>
          <Route path="/privacypolicy" element={<Confidential/>}/>
          <Route path="/about" element={<About/>}/>
      </Route>
      <Route path='/admin'element={<AdminLayout/>}>
          <Route path="/admin" element={<AdminHome />}/>
          <Route path="/admin/addproduct" element={<AddProduct />}/>
          <Route path="/admin/updateproduct/:id" element={<UpdateProduct />}/>
      </Route>
      <Route path='/login'element={<Login/>}></Route>
      
      {/* <Route path="*" element={
        <Suspense fallback={<div>Идёт загрузка...</div>}>
          <NotFound />
        </Suspense>
      }
        /> */}
    </Routes>
    </div>
  );
}

export default App;
