import { Link, useNavigate } from 'react-router-dom'
import css from './Header.module.css'
import { useState } from 'react';
import HttpsUtils from '../../../http/utils'
import mobx from '../../../mobx/mobx'

const Header = () => {
    const [modal, setModal] = useState(false)
    let navigate = useNavigate();

    const logout = async() => {
        const bool = await HttpsUtils.logout()
        if(bool){
            navigate(`/`);
        }
    }
    const domain = new URL(process.env.REACT_APP_CLIENT_URL).hostname;
    return (
        <section className={css.container}>
            <Link to='/' className={css.domain}>{domain}</Link>
            <div className={css.adminContainer}>
                <span className={css.admin} onClick={() => setModal(!modal)}>Админ</span>
                {modal &&
                    <div className={css.adminModal}>
                        <span onClick={()=>mobx.setUserModal(true)}>Данные пользователя</span>
                        <span onClick={logout}>Выход</span>
                    </div>
                }

            </div>
        </section>
    )
}

export default Header