import { Link } from 'react-router-dom'
import css from './Footer.module.css'
import logo from './img/logo.png'
import { Link as ScrollLink } from 'react-scroll';

const Footer = () => {
    return (
        <section className={css.container}>  
            <div className={css.logoBox}>
                <ScrollLink spy={true} smooth={true} offset={-200} duration={1500} to='hero'><img src={logo} className={css.logo}/></ScrollLink>
                
            </div>
            <div className={css.textContainer}>
                <h2 className={css.header}>Информация</h2>
                <Link className={css.link}>Сертификаты</Link>
                <Link className={css.link}>Станьте партнером</Link>
            </div>
            <div className={css.textContainer}>
                <Link to='/privacypolicy' className={`${css.link} ${css.conf}`}>Политика конфиденциальности</Link>
                <span className={css.link}>Разработано и поддерживается веб-студией <a href='https://web-hub.pro' className={css.wh}>Web-Hub</a></span>
            </div>
        </section>
    )
}

export default Footer