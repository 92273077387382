import css from "./AddProduct.module.css";
import { observer } from "mobx-react-lite";
import camera from "./img/camera.png";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion'
import mobx from '../../mobx/mobx'
import Data from '../../mobx/data'
import HttpUtils from '../../http/utils'
import { Link, useNavigate } from "react-router-dom";


const AddProduct = observer(() => {
  const dropContainer = document.getElementById("dropcontainer")
  const fileInput = document.getElementById("images")
  const [key, setKey] = useState()
  const [value, setValue] = useState()
  const [typeId, setTypeId] = useState(0)
  const [catrgoryModal, setCategoryModal] = useState(false)
  const [categoryname, setCategoryname] = useState('---------')
  const [categoryid, setCategoryid] = useState(0)
  const [nal, setNal] = useState(true)
  const [imageURL, setImageURL] = useState(null);
  const [types, setTypes] = useState([{ "key": "", "value": "" }])
  const [file, setFile] = useState()
  const [name , setName] = useState()
  const [count , setCount] = useState()
  const [priceOne , setPriceOne] = useState()
  const [pricePack , setPricePack] = useState()
  const [article , setArticle] = useState()
  const [description, setDescription] = useState()
  let navigate = useNavigate();

  // Функция для созддания временного юрл для вновь загруженого изображения
  const handleImageUpload = (event) => {
    const fileUpload = event.target.files[0]
    setFile(fileUpload)
    if (fileUpload) {
      const imageURL = URL.createObjectURL(fileUpload);
      setImageURL(imageURL);
    }
  };

  // Запрос на получение списка категорий
  useEffect(() => {
    Data.getCategoryData()
  }, [])

  // Функция для формирования и отправки запрса на создание товара
  const Add = async () => {
    const formData = new FormData();
    if(key && value){
      const newTypes = [...types]; // Создаем копию массива types
      newTypes[types.length -1].key = key; // Обновляем значение key в копии массива
      newTypes[types.length -1].value = value; // Обновляем значение key в копии массива

    }
    const asynctypesJson = JSON.stringify(types)
    if(!categoryid || categoryname === '---------'){
      alert ('Укажите категорию!')
      return
    }
    if(
      file){
          formData.append('img', file);
          formData.append('name', name);
          formData.append('priceOne', priceOne);
          formData.append('pricePack', pricePack);
          formData.append('nalichie', nal);
          formData.append('article', article);
          formData.append('description', description);
          formData.append('count', count);
          formData.append('categoryId', categoryid);
          formData.append('types', asynctypesJson);

          const id = await HttpUtils.createProduct(formData)
          navigate(`/admin?id=${categoryid}`);
      }else{
          alert("Выберите изображение!")
      }
  }


  // Функция обновляет значение в стейте характеристик и добавляет новы обьект
  const handleKeyChange = (index) => {
    const newTypes = [...types]; // Создаем копию массива types
    newTypes[index].key = key; // Обновляем значение key в копии массива
    newTypes[index].value = value; // Обновляем значение key в копии массива
    setTypes(newTypes); // Обновляем состояние types
    setTypes([...types, { key: "", value: "" }]);
    setKey('')
    setValue('')
    setTypeId(typeId+1)
  };

  // Функция для удаления обьекта из массива по индексу
  const handleRemoveType = (index) => {
    const newTypes = [...types]; // Создаем копию массива types
    newTypes.splice(index, 1); // Удаляем элемент с указанным индексом
    setTypes(newTypes); // Обновляем состояние types
    setTypeId(typeId-1)
  };

  return (
    <div className={css.container}>
      <Link to='/admin' className={css.exit}>Назад</Link>
      <AnimatePresence mode={ "popLayout"}>
      <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.modalWind}>
        <div className={css.imgContainer}>
          <label htmlFor="images" className={css.dropContainer} id="dropcontainer">

            <input type="file" id="images" onChange={handleImageUpload} className={css.inputFile} required />
            <img src={imageURL ? imageURL : camera} className={css.img} />
          </label>
        </div>
        <div className={css.textContainer}>
          <input placeholder="Название продукта" type="text" value={name} onChange={(e) => setName(e.target.value)} className={css.inputName} />
          <div className={css.inputContainer}>
            <span className={css.inputType}>Код товара: </span>
            <input className={css.inputValue} placeholder="123456" value={article} onChange={(e) => setArticle(e.target.value)} type="number" />
          </div>
          <div className={css.inputContainer}>
            <span className={css.inputType}>Кол-во в упаковке: </span>
            <input className={css.inputValue} placeholder="100" value={count} onChange={(e) => setCount(e.target.value)} type="number" />
            <span>шт.</span>
          </div>
          <div className={css.inputContainer}>
            <span className={css.inputType}>Цена за 1 шт.: </span>
            <input className={css.inputValue} placeholder="100" value={priceOne} onChange={(e) => setPriceOne(e.target.value)} type="number" />
            <span>руб.</span>
          </div>
          <div className={css.inputContainer}>
            <span className={css.inputType}>Цена за упаковку: </span>
            <input className={css.inputValue} placeholder="1000" value={pricePack} onChange={(e) => setPricePack(e.target.value)} type="number" />
            <span>руб.</span>
          </div>
          <div className={css.inputContainer}>
            <span className={css.inputType}>Наличие: </span>
            <div className={css.checkbox} onClick={() => setNal(!nal)}>
              {nal && <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>✔</motion.span>}
            </div>
          </div>
          <div className={css.inputContainer}>
            <span className={css.inputType}>Категория: </span>
            <div className={css.categoryContainer}>
              <span className={css.categoryBurgerTrigger} onClick={() => { setCategoryModal(!catrgoryModal) }}>{categoryname}</span>

              {catrgoryModal && mobx.categoryArray &&
                <motion.ul initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={css.categoryModal}>
                  {mobx.categoryArray.map(category => (
                    <li key={category.id} onClick={() => { setCategoryname(category.name); setCategoryid(category.id); setCategoryModal(false) }}>{category.name}</li>
                  ))}
                </motion.ul>
              }

            </div>
          </div>
          <div className={`${css.inputContainer} ${css.descriptionTitle}`}>
            <span className={`${css.inputType}`}>Описание: </span>
            <textarea className={css.description} value={description} onChange={e => setDescription(e.target.value)}/>
          </div>

        </div>

      </motion.div>
      </AnimatePresence>
      <div className={`${css.typesContainer}`}>
        <span className={`${css.typesTitle}`}>Характеристики: </span>
        {types.map((card, index) => (
          <motion.div initial={{opacity:0}} animate={{opacity:1}} key={index} className={css.typesCard}>
            <input className={css.keyInput} value={types[index].key ? types[index].key : key} onChange={(e) => setKey(e.target.value)} type="text" />
            <input className={css.valueInput} value={types[index].value ? types[index].value : value} onChange={(e) => setValue(e.target.value)} type="text" />
            {
              typeId===index?
              <button className={css.typesButton} onClick={() => handleKeyChange(index)}>Добавить</button>
              :
              <button className={css.typesButton} onClick={() => handleRemoveType(index)}>Удалить</button>
            }
            
          </motion.div>
        ))}
      </div>
      <button className={css.button} onClick={()=>Add()}>Отправить</button>
    </div>
  );
});

export default AddProduct;
