import css from './UserModal.module.css'
import mobx from '../../../mobx/mobx'
import Data from '../../../mobx/data'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import PasswordUpdateModal from '../PasswordUpdateModal/PasswordUpdateModal'
import EmailUpdateModal from '../EmailUpdateModal/EmailUpdateModal'

const UserModal = observer(() => {
  useEffect(()=>{
    Data.getUser()
  },[])
  return (
<section className={css.container} onClick={()=>mobx.setUserModal(false)}>
  {mobx.PasswordUpdateModal&&<PasswordUpdateModal/>}
  {mobx.EmailUpdateModal&&<EmailUpdateModal/>}
    <div className={css.modalWind} onClick={e => e.stopPropagation()}>
      <span className={css.email}>{mobx.User.email}</span>
      {
        mobx.User.isActivated?
        <span className={css.isActivated}>Email подтвержден</span>:
        <span className={css.isActivated}>Email не подтвержден</span>
      }
      {mobx.User.email2&&
        <span className={css.email2}>Письмо с подверждением отправлено на <a href={`${mobx.User.email2}`}>{mobx.User.email2}</a></span>
      }
      <div className={css.buttonContainer}>
          <button onClick={()=> mobx.setPasswordUpdateModal(true)}>Изменить пароль</button>
          <button onClick={()=> mobx.setEmailUpdateModal(true)}>Изменить email</button>
      </div>
    </div>
</section>
  )
})

export default UserModal