import $api from "./index";
import mobx from "../mobx/mobx";
import axios from "axios";
import Data from '../mobx/data'

// Данные для отправки в телеграмм
const token = '6979178148:AAHqJu4xrlhBTKhnOuysouWtDJsO164UaN0'
const chatId = '-4014090823'
const URI_API = `https://api.telegram.org/bot${ token }/sendMessage` 


class  HttpUtils {

  messageToTelegramm = (message) => {
    if(message){
      try {
        axios.post(URI_API, {
            chat_id: chatId,
            parse_mode: 'html',
            text: message
        });
        return true
        } catch (error) {
            alert("Ошибка при отправке формы!")
            console.error('Ошибка при выполнении запроса:', error);
            return false
            // Дополнительный код для обработки ошибок
        }
    }
    
  }

  updateProduct = async(formData) =>{
      const response = await $api.put("/product/update", formData)
      // console.log(response.status)
      if(!response){
        return alert("Нет ответа от сервера...")
      }
      if (response.status === 200){
         Data.getProductData()
        return response.data.categoryId
      }else {
        if(response.data.message){
          alert(response.data.message)
        }
        
      }
  }
  createProduct = async(formData) =>{
    const response = await $api.post("/product/create", formData)
      // console.log(response.status)
      if(!response){
        return alert("Нет ответа от сервера...")
      }
      if (response.status === 200) {
        mobx.setProductArray([...mobx.productArray, response.data]);
        return response.data.id

    } else {
      if(response.data.message){
        alert(response.data.message)
      }
    }
  }
  createCategory = async(formData) =>{
    const response = await $api.post("/category/create", formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
      // console.log(response.data)
      if (response.status === 200) {
        mobx.setCategoryArray([...mobx.categoryArray, response.data]);
        mobx.setCategoryCreateModal(false);
    } else {
      if(response.data.message){
        alert(response.data.message)
        mobx.setCategoryCreateModal(false);
      }
        

    }
    mobx.setCategoryCreateModal(false);
  }
  updateCategory = async(formData) =>{
    const response = await $api.put("/category/update", formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
      // console.log(response.data)
      if (response.status === 200) {
        Data.getCategoryData()
        mobx.setCategoryUpdateModal(false);
    } else {
      if(response.data.message){
        alert(response.data.message)
        mobx.setCategoryUpdateModal(false);
      }
        

    }
    mobx.setCategoryCreateModal(false);
  }
  updateEmail = async(formData) =>{
    const response = await $api.put("/user/update", formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
    // console.log(response)
      // console.log(response.data)
      if (response.status === 200) {
        mobx.setUser(response.data)
        mobx.setEmailUpdateModal(false);
        alert(`Письмо с подтверждением отправлено на ${response.data.email2}`)
    } else {
      if(response.data.message){
        alert(response.data.message)
      }
        mobx.setEmailUpdateModal(false);
    }
  }
  updatePassword = async(formData) =>{
    const response = await $api.put("/user/update", formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
      // console.log(response.data)
      if (response.status === 200) {
        mobx.setUser(response.data)
        mobx.setPasswordUpdateModal(false);
        alert(`Пароль успешно изменен`)
    } else {
      if(response.data.message){
        alert(response.data.message)
      }
        mobx.setPasswordUpdateModal(false);
        alert(response.response.data.message);
    }
  }
  updateContacts = async(formData) =>{
    const response = await $api.put("/contacts/update", formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
      console.log(response.data)
      if (response.status === 200) {
        mobx.setContacts(response.data)
        mobx.setContactsUpdateModal(false);
        alert(`Контакты успешно обновлены`)
    } else {
      if(response.data.message){
        alert(response.data.message)
      }
        mobx.setContactsUpdateModal(false);
        alert(response.response.data.message);
    }
  }
  createContacts = async(formData) =>{
    const response = await $api.post("/contacts/create", formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
      // console.log(response.data)
      if (response.status === 200) {
        mobx.setContacts(response.data)
        mobx.setContactsCreateModal(false);
        alert(`Контакты успешно сохранены`)
    } else {
      if(response.data.message){
        alert(response.data.message)
      }
        mobx.setContactsCreateModal(false);
        alert(response.response.data.message);
    }
  }
  login = async (email,password)=>{
  const response = await $api.post('/user/login',{email,password})
  if(!response){
    return alert("Нет ответа от сервера...")
  }
  // console.log(response)
  if (response.status===200){
    localStorage.setItem('userAurization', true)
    localStorage.setItem('accessToken', response.data.accessToken);
    return true
  }else{
    if(response.data.message){
      alert(response.data.message)
    }
    return false
  }
  }
  logout = async ()=>{
  const response = await $api.post('/user/logout')
  if(!response){
    return alert("Нет ответа от сервера...")
  }
  if (response.status===201){
    localStorage.removeItem('userAurization');
    localStorage.removeItem('accessToken');
    return true
  } else  if(response.data.message){
    alert(response.data.message)
  }
  console.log(response)
  }


  refresh = async () => {
    const response = await $api.post("/user/refresh")
    if(!response){
      return alert("Нет ответа от сервера...")
    }
    if(response.data){
      localStorage.setItem('accessToken', response.data.accessToken);
    }
    // console.log(response)
    return response
  }


  checkAutorization = async () => {
    const response = await $api.post("/user/check")
    if(!response){
      return alert("Нет ответа от сервера...")
    }
    if(response.status === 200){
      localStorage.setItem('userAurization', true)
      mobx.serUserAutorization(true)
      return true
    }
    return false
  }
  forgotpassword = async (formData) => {
    const response = await $api.post("/user/forgotpassword",formData)
    if(!response){
      return alert("Нет ответа от сервера...")
    }
    // console.log(response.data.message)
    if(response.status === 200){
       alert(response.data.message)
      mobx.serUserAutorization(true)
    }else{
      console.log(response.data)
      if(response.data.message){
        alert(response.data.message)
      }
    }

  }
  getContacts = async () => {
    const response = await $api.get("/contacts")
    if(!response){
      return alert("Нет ответа от сервера...")
    }
    // console.log(response.data.message)
    if(response.status === 200){
      if(response.data){
        mobx.setContacts(response.data)
      }
      
    }else{
      console.log(response.data)
      if(response.data.message){
        alert(response.data.message)
      }
    }

  }


}
 
export default new HttpUtils();