import { useParams } from 'react-router-dom';
import css from './Product.module.css'
import { useEffect, useLayoutEffect, useState } from 'react';
import Data from '../../../mobx/data'
import mobx from '../../../mobx/mobx'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'

const Product = observer(() => {
    const { id } = useParams()
    const [types, setTypes] = useState()

    useLayoutEffect(() => {
        async function fetchData() {
            if (id) {
                try {
                    const data = await Data.getProductOnIdData(id);
                    if (data.types) {
                        setTypes(JSON.parse(data.types))
                    }
                } catch (error) {
                    console.error("Ошибка при получении данных:", error);
                }
            }
        }
    
        fetchData();
    }, [id]);
    return (
        <section className={css.container}>
            {mobx.oneProduct &&
                <div className={css.cardColumn}>
                    <h1 className={css.header}>{mobx.oneProduct.name}</h1>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={css.cardContainer}>
                        <div className={css.imgContainer}>
                            <img src={mobx.oneProduct.img && `${process.env.REACT_APP_API_HOST}${mobx.oneProduct.img}`} className={css.cardImg} />
                        </div>
                        <div className={css.textContainer}>
                            <div className={css.articleContainer}>
                                <span>Код товара: {mobx.oneProduct.article}</span>
                                <span>Кол-во в упаковке: {mobx.oneProduct.count} шт</span>
                            </div>
                            <span className={css.cardText1}>Весь товар продается исключительно упаковками. При заказе на сумму до 10 000 руб. действует наценка 20% к указанной цене.</span>
                            <h3 className={css.pricetitle}>Цена за 1 шт.</h3>
                            <span className={css.price}>{mobx.oneProduct.priceOne} руб.</span>
                            <h3 className={css.pricetitle}>Цена за упаковку</h3>
                            <span className={css.price}>{mobx.oneProduct.pricePack} руб.</span>
                            {mobx.oneProduct.nalichie ?
                                <div className={css.nalichieOk}>В наличии</div>
                                :
                                <div className={css.nalichieNo}>Нет в наличии</div>
                            }
                            <button className={css.button} onClick={() => mobx.setApplicationModal(true)}>Заказать</button>
                        </div>
                    </motion.div>
                    <div className={css.descriptionContainer}>
                        <div className={css.column}>
                            <h2 className={css.descriptionHeader}>Описание</h2>
                            <span className={css.decription}>{mobx.oneProduct.description}</span>
                        </div>
                        <div className={css.column}>
                            <h2 className={`${css.descriptionHeader} ${css.typesHeader}`}>Характеристики</h2>
                            {
                                types &&
                                types.map(type => (
                                    <div key={type.key} className={css.typesCard}>
                                        <h3 className={css.typesTitle}>{`${type.key}`}</h3>
                                        <span className={css.typesDescr}>{`${type.value}`}</span>
                                    </div>

                                ))}
                        </div>
                    </div>
                </div>

            }

        </section>
    )
})

export default Product