
import $api from "../http";
import mobx from "./mobx";


class  Data {

        // Функция для получения списка продуктов и помещения его в глобальный массив
        getProductData = async () => {
            try {
                //Получаю список категорий с сервера
              const response = await $api.get('/product')
              // Помещаю их в глобальную переменную
              mobx.setProductArray(response.data) 
            } catch (error) {
              console.error('Ошибка при получении списка продуктов:', error);
            }
          };
        // Функция для получения списка продуктов и помещения его в глобальный массив
        getProductOnCatData = async (categoryId) => {
          // mobx.setBodyIsLoading(true)
            try {
                //Получаю список категорий с сервера
                const response = await $api.get(`/product/cat/${categoryId}`)
              // Помещаю их в глобальную переменную
              if(response.data){
                mobx.setProductArray(response.data) 
                // mobx.setBodyIsLoading(false)
              }else{
                mobx.setProduct([]) 
              }
              
            } catch (error) {
              console.error('Ошибка при получении списка категорий:', error);
            }
          };
        // Функция для получения списка продуктов и помещения его в глобальный массив
        getProductOnIdData = async (Id) => {
          // mobx.setBodyIsLoading(true)
            try {
                //Получаю список категорий с сервера
                const response = await $api.get(`product/one/${Id}`)
              // Помещаю их в глобальную переменную
              if(response.data){
                mobx.setOneProduct(response.data) 
                return response.data
                // mobx.setBodyIsLoading(false)
              }else{
                mobx.setOneProduct([]) 
              }
              
            } catch (error) {
              console.error('Ошибка при получении списка категорий:', error);
            }
          };
        // Функция для получения списка категорий и помещения его в глобальный массив
        getCategoryData = async () => {
          // mobx.setCategoryIsLoading(true)
        try {
            //Получаю список категорий с сервера
            const response = await $api.get(`/category`)
            // Помещаю их в глобальную переменную
            if(response.data){
              mobx.setCategoryArray(response.data) 
              // mobx.setCategoryIsLoading(false)
            }else{
              mobx.setCategoryArray([]) 
            }
            
        } catch (error) {
            console.error('Ошибка при получении списка категорий:', error);
        }
        };

        // Функция удаляет обьект
        deleteProduct = async (id) => {
          const response = await $api.delete(`/product/delete`,{ data: { id } })
            mobx.setProductArray(mobx.productArray.filter(item => item.id !== id)) 
            // console.log(mobx.product)
      }
        // Функция удаляет категорию
        deleteCategory = async(id)=>{
          const response = await $api.delete(`/category/delete`,{ data: { id } })
          if(!response){
            alert("Нет ответа от сервера...")
          }
          if(response.status === 200){
            if(mobx.categoryArray.length >1){
              this.getCategoryData()
              mobx.setCategoryId(mobx.categoryArray[0].id)
            }else{
              mobx.setCategoryArray([]) 
            }
            mobx.setCategoryUpdateModal(false)
          }else if (response.status >=400){
            if(response.data.message){
              alert(response.data.message)
            }
          }
            // console.log(mobx.product)
      }
        // Функция удаляет обьект
        getUser = async()=>{
          const response = await $api.get(`/user`)
          if(response && response.status===200){
            mobx.setUser(response.data)
          }
          else(
            console.log(response)
          )
      }



}
 
export default new Data();