import css from './CategoryUpdateModal.module.css'
import mobx from '../../../mobx/mobx'
import { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import HttpUtils from '../../../http/utils'
import Data from '../../../mobx/data'

const CategoryUpdateModal = () => {
    const [name , setName] = useState()
    const [index , setIndex] = useState()

    useEffect(()=>{
        if(mobx.categoryArray){
            mobx.categoryArray.forEach(category => {
                if(category.id === mobx.categoryId){
                    setName(category.name)
                    setIndex(category.index)
                }
            })
        }
    },[])
    const update = () => {
        if(name && index){
            const formData = new FormData();
            formData.append('id', mobx.categoryId);
            formData.append('name', name);
            formData.append('index', index);
            HttpUtils.updateCategory(formData)
        }else{
            alert('Заполните все поля!')
        }
    }

  return (
<motion.section initial={{opacity:0}} animate={{opacity:1}} className={css.container} onClick={()=>mobx.setCategoryUpdateModal(false)}>
    <div className={css.modalWind} onClick={e => e.stopPropagation()}>
        <h2 className={css.header}>Ректирование категории</h2>
        <input className={css.inputName} value={name} onChange={(e)=>setName(e.target.value)} placeholder='Название категории' type='text'/>
        <input className={css.inputName} value={index} onChange={(e)=>setIndex(e.target.value)} placeholder='Индекс категории' type='number'/>
        <div className={css.buttonContainer}>
        <button className={css.button} onClick={update} >Изменить</button>
        <button className={css.button} onClick={()=>Data.deleteCategory(mobx.categoryId)} >Удалить</button>
        </div>

    </div>
</motion.section>
  )
}

export default CategoryUpdateModal