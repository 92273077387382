import { useEffect } from 'react';
import BodyCatalog from '../../../components/HomeComponents/BodyCatalog/BodyCatalog'
import { Sidebar } from '../../../components/HomeComponents/Sidebar/Sidebar'
import css from './Catalog.module.css'
import { useParams, useSearchParams } from 'react-router-dom';
import mobx from '../../../mobx/mobx'
import qs from 'qs'

const Catalog = () => {
  useEffect(()=>{
      window.scrollTo(0, 0);
  },[])
  return (
<section className={css.container}>
    <Sidebar/>
    <BodyCatalog/>
</section>
  )
}

export default Catalog