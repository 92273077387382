import ApplicationModal from '../ApplicationModal/ApplicationModal';
import Header from '../Header/Header'
import Marque from '../Marque/Marque'
import css from './HomeLayout.module.css'
import { Outlet } from 'react-router-dom';
import mobx from '../../../mobx/mobx'
import { observer } from 'mobx-react-lite'
import Footer from '../Footer/Footer';

const HomeLayout = observer(() => {
  return (
    <div className={css.container}>
        {mobx.applicationModal&&<ApplicationModal/>}
        <Marque/>
        <Header/>
        <Outlet />
        <Footer/>
    </div>
  )
})

export default HomeLayout
