import css from './Sidebar.module.css'
import Data from '../../../mobx/data'
import mobx from '../../../mobx/mobx'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import qs from 'qs'
import { useNavigate } from 'react-router-dom'
import {Link} from 'react-router-dom'
import edit from './img/edit.png'
import {motion} from 'framer-motion'


export const Sidebar = observer(() => {
    const navigate = useNavigate()

    useEffect(()=>{
        Data.getCategoryData()  
        if(window.location.search){
            const params = qs.parse(window.location.search.substring(1))
            if(params.id ){
                mobx.setCategoryId(parseInt(params.id)) 
                Data.getProductOnCatData(mobx.categoryId)  
            }
          }else{
            Data.getProductData()
        } 
    },[])


    const handleCategoryId = (id) => {
        mobx.setCategoryId(id)
        const queryString = qs.stringify({id:mobx.categoryId})
        navigate(`?${queryString}`)
        Data.getProductOnCatData(mobx.categoryId)
    }

  return (
<section className={css.container}>
    <>
    <div  className={css.Allcategory}>
            <span onClick={()=> {Data.getProductData();mobx.setCategoryId(0)}}>Все</span>
    </div>
    {mobx.categoryArray&&
    mobx.categoryArray.map(category => (
        <div key={category.id} className={category.id===mobx.categoryId?`${css.category} ${css.active}`:`${css.category}`}>
            <span onClick={()=>handleCategoryId(category.id)}>{category.name}</span>
            {category.id === mobx.categoryId&& <motion.img onClick={()=>mobx.setCategoryUpdateModal(true)} initial={{opacity:0}} animate={{opacity:1}} src={edit} className={css.editImg}/>}
            
        </div>
    ))
    }
    <div  className={`${css.add}`}>
            <span  className={css.link} onClick={()=>mobx.setCategoryCreateModal(true)}>Добавить категорию</span>
            <Link  className={css.link} to={`/admin/addproduct`} >Добавить товар</Link>
        </div>
    </>
</section>
  )
})
