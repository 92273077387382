import css from './ForgotPassword.module.css'
import mobx from '../../../mobx/mobx'
import {observer} from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import {  motion} from 'framer-motion'
import gsap from 'gsap'
import HttpUtils from '../../../http/utils'
import { isEmail } from 'validator';


const ForgotPassword =  observer(() => {
    const [email,setTmail] = useState()
    const modalRef = useRef()


    const close = () =>{
        gsap.to(modalRef.current,{opacity:0})
        setTimeout(()=>{mobx.setForgotPasswordModal(false)},[300])
    }

    const forgotPassword = () =>{
        if(email && isEmail(email)){
            const formData = new FormData();
            formData.append('email', email);
            gsap.to(modalRef.current,{opacity:0})
            setTimeout(()=>{HttpUtils.forgotpassword(formData)},[300])
        }else{
            alert("Не корректный email!")
        }
    }
    const handleModalClick = (e) => {
        e.stopPropagation(); // Предотвращаем всплытие события клика на внутренних элементах
    }

    useEffect(()=>{
        setTmail('')
    },[mobx.ForgotPasswordModal])
    return ( 
<>
{mobx.ForgotPasswordModal&&
    <motion.section ref={modalRef} initial={{opacity: 0 }} whileInView={{opacity:1}} className={css.container} onClick={()=>mobx.setForgotPasswordModal(false)}>
        <div className={css.modalWind} onClick={handleModalClick}>
        <div className={css.close} onClick={close}>x</div>
        <input className={css.inputName} placeholder='Введите email' value={email} onChange={(e)=> setTmail(e.target.value)}  />
        <div className={css.button} onClick={()=>forgotPassword()}>Отправить письмо</div>
        </div>
    </motion.section>
}
</>
     );
})
 
export default ForgotPassword;