import { useEffect, useState } from 'react';
import css from './Contacts.module.css'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const Contacts = () => {
    const [width,setWidth] = useState('30vw')
    const [height,setHeight] = useState('20vw')
    const coordinates = [45.035490, 38.975231]

    useEffect(()=>{
        const handleSize = () => {
            if(window.screen.width<769){
                setWidth('90vw')
                setHeight('90vw')
            }else{
                setWidth('30vw')
                setHeight('20vw')
            }
        }
        handleSize()
        window.addEventListener('resize', handleSize)
        return () => {
            window.removeEventListener('resize', handleSize)
        }

    },[])
  return (
<section className={css.container} id='contacts'>
    <div className={css.textContainer}>
        <h2 className={css.header}>Контакты</h2>
        <a href='#' className={css.email}>+7 (906) 139-00-01</a>
        <a href='#' className={css.email}>mail@mail.ru</a>
        <span className={css.cityHeader}>Краснодар</span>
        <span className={css.cityAdress}>ул. Уральская 2Б</span>
    </div>
    <div className={css.mapContainer}>
        <YMaps>
            <Map  width={width} height={height} defaultState={{ center: coordinates, zoom: 9 }}>
                <Placemark  defaultGeometry={coordinates} />
            </Map>
        </YMaps>
    </div>
</section>
  )
}

export default Contacts