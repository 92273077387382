import css from './Service.module.css'
import img from './img/img.png'

const Service = () => {
  return (
<section className={css.container}>

    <div className={css.textContainer}>
        <h2 className={css.header}>Выпуск продукции под вашей торговой маркой</h2>
        <h2 className={css.descr}>Мы предлагаем индивидуальное изготовление продукции с учетом вашего бренда. 
        Наша компания специализируется на производстве разнообразных бытовых товаров, включая губки для мытья посуды, мочалки и салфетки. 
        Наш опыт и профессионализм позволяют нам создавать продукцию высокого качества, которая не только соответствует вашим требованиям, 
        но и подчеркивает уникальность вашего бренда. Доверьте нам производство, и мы обеспечим ваш бренд продукцией, которая впечатлит 
        ваших клиентов и поддержит вашу репутацию на рынке.</h2>
    </div>
    <div className={css.imgContainer}>
        <img src={img} className={css.img}/>
    </div>
</section>
  )
}

export default Service