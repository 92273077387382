import css from './Marque.module.css'
import Marquee from 'react-fast-marquee'

const Marque = () => {
  return (
<div className={css.container}>
<Marquee className={css.Marquee} autoFill={true}>
С 1 ДЕКАБРЯ СКИДКА 15% ОТ ЦЕНЫ НА САЙТЕ НА ВЕСЬ АССОРТИМЕНТ! <span>•</span>
</Marquee>
</div>

  )
}

export default Marque
