import { useEffect } from 'react'
import BodyCatalog from '../../components/AdminComponent/BodyCatalog/BodyCatalog'
import { Sidebar } from '../../components/AdminComponent/Sidebar/Sidebar'
import css from './AdminHome.module.css'
import HttpUtils from '../../http/utils'

const AdminHome = () => {
  useEffect(()=>{
    HttpUtils.checkAutorization()
  },[])
  return (
    <div className={css.container}>
      <Sidebar/>
      <BodyCatalog/>
    </div>
  )
}

export default AdminHome
