import { useEffect, useState } from 'react'
import css from './ApplicationModal.module.css'
import InputMask from 'react-input-mask';
import mobx from '../../../mobx/mobx'
import {motion} from 'framer-motion'

const ApplicationModal = () => {
    const [name,setName] = useState()
    const [number, setNumber] = useState()

    // useEffect(()=>{
    //     document.body.classList.add('popup-open');

    //     return ()=>{
    //         document.body.classList.remove('popup-open');
    //     }
    // },[])
  return (
    <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.container} onClick={()=>mobx.setApplicationModal(false)}>
        <motion.div initial={{opacity:0,scale:0}} whileInView={{opacity:1,scale:1}}  className={css.close}>x</motion.div>
        <div className={css.modalWind} onClick={e => e.stopPropagation()}>
            <h2 className={css.header}>Оставьте номер телефона.</h2>
            <span className={css.header2}>И мы перезвоним Вам, чтобы проконсультировать и предложить особые условия!</span>
            <input placeholder={'Введите имя'} value={name} onChange={(e) => setName(e.target.value)} className={css.inputName}/>
            <InputMask className={css.inputNumber} value={number} onChange={(e) => setNumber(e.target.value)}  mask="+7 (999) 999 99-99" placeholder='Номер телефона' />
            <button className={css.button}>Отправить</button>
        </div>
    </motion.div>
  )
}

export default ApplicationModal