import { useState } from 'react'
import css from './Сonsultation.module.css'
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';

const Сonsultation = () => {
    const [name, setName] = useState()
    const [number, setNumber] = useState()
    return (
        <section className={css.container}>
            <div className={css.cubeContainer}>
                <span></span><span></span><span></span>
            </div>
            <div className={css.formContainer}>
                <input placeholder={'Введите имя'} value={name} onChange={(e) => setName(e.target.value)} className={css.inputName} />
                <InputMask className={css.inputNumber} value={number} onChange={(e) => setNumber(e.target.value)} mask="+7 (999) 999 99-99" placeholder='Номер телефона' />
                <button className={css.button}>Отправить</button>

            </div>
            <div className={css.textContainer}>
                <h2 className={css.header}>Закажите образцы бесплатно</h2>
                <span className={css.header1}>Если не можете определиться с выбором, мы отправим вам образцы доски совершенно бесплатно</span>
                <span className={css.descr}>Даю согласие на обработку моих персональных данных и соглашаюсь с <Link to='/privacypolicy' className={css.link}>политикой конфиденциальности.</Link></span>
            </div>

        </section>
    )
}

export default Сonsultation