import css from './Products.module.css'
import { useEffect } from 'react'
import Data from '../../../mobx/data'
import mobx from '../../../mobx/mobx'
import { observer } from 'mobx-react-lite'
import {Link} from 'react-router-dom'

const Products = observer(() => {
  useEffect(() => {
    Data.getProductData()
  }, [])
  return (
    <div className={css.container}>
      <h1 className={css.header}>МЫ ПРОИЗВОДИМ</h1>
      <div className={css.cardContainer}>
        {mobx.productArray &&
          mobx.productArray.slice(0, 4).map(card => (
            <div key={card.id} className={css.card}>
              <img src={`${process.env.REACT_APP_API_HOST}${card.img}`} className={css.cardImg} />
              <h2 className={css.cardHeader}>{card.name}</h2>
              <span className={css.cardArticle}>{`Артикул: ${card.article}`}</span>
              <span className={css.price}>{`Цена: ${card.pricePack} руб.`}</span>
              <div className={css.buttonContainer}>
                <Link to={`/product/${card.id}`} className={css.button1}>Подробнее</Link>
                <button className={css.button2} onClick={()=>mobx.setApplicationModal(true)}>Заказать</button>
              </div>
            </div>
          ))
        }
      </div>
        <Link to='/catalog' className={css.button}>Открыть каталог</Link>
    </div>
  )
})

export default Products
